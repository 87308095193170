export const $ = document
export const $q = sel => document.querySelector(sel)
export const $$ = query => Array.from(document.querySelectorAll(query));
export const changeClasses = (el, className) => (el.className = className);

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export const interleaveArray = (array1, array2) => array1.map((v,i) => [v, array2[i]]).reduce((a,b) => a.concat(b));

export const append = (parent, htmlString) => {
    const fragment = document.createRange().createContextualFragment(htmlString);
    parent.appendChild(fragment)
}

const strToEl = str => {
    if (typeof str === 'string') return $q(str)
    return str
}

export const addClass = (selector, cl) => {
    strToEl(selector).classList.add(cl)
} 

export const toggleClass = (selector, cl) => {
    const el = strToEl(selector)
    el.classList.toggle("open")
}

export const rmClass = (selector, cl) => {
    strToEl(selector).classList.remove(cl)
}

export const on = (eventType, selector, eventHandler) => {
    document.addEventListener(eventType, (event) => {
        let parent 
        if (event.target.localName === "body") parent = event.target
        if (event.target.closest) parent = event.target.closest(selector)
        if (!parent) return;
        // console.log("EVENT ON: ", event, parent)
        eventHandler(event, parent)
    }, false);
}

export const emit = (eventName, obj = {}) => {
    var event = new CustomEvent(eventName, obj)
    // console.log("EVENT EMIT", obj)
    document.dispatchEvent(event);
}

export const unHide = (selector) => {
    if (typeof selector === 'string')
        $.querySelector(selector).classList.remove("hidden")
    else
        selector.classList.remove("hidden")
}

export const hide = (selector) => {
    if (typeof selector === 'string')
        $.querySelector(selector).classList.add("hidden")
    else
        selector.classList.add("hidden")
}

export const domNotFound = dom => console.error(`No ${dom} found on the DOM.  Please add the component.`)

let PRELOADED_URLS = []
const appendLink = (url) => {
    if (PRELOADED_URLS.includes(url)) return
    let preloadLink = document.createElement("link");
    preloadLink.href = url;
    preloadLink.rel = "preload";
    preloadLink.as = "script";
    document.head.appendChild(preloadLink);
    PRELOADED_URLS.push(url)
}

export const preloadScripts = (urls) => {
    if (typeof urls === 'string') appendLink(urls)
    if (Array.isArray(urls)) {
        for (let i = 0; i < urls.length; i++) {
            appendLink(urls[i])
        }
    }
}

let LOADED_URLS = []
const appendJSScript = (url) => {
    if (LOADED_URLS.includes(url)) return
    let preloadedScript = document.createElement("script");
    preloadedScript.src = url;
    document.body.appendChild(preloadedScript);
    LOADED_URLS.push(url)
}

export const loadScripts = (urls) => {
    if (typeof urls === 'string') appendJSScript(urls)
    if (Array.isArray(urls)) {
        for (let i = 0; i < urls.length; i++) {
            appendJSScript(urls[i])
        }
    }
}

HTMLVideoElement.prototype.playTo = function(newTime) {
    // this.pause();
    const MARGIN = .3;
    var video = this;
    var fps = 60;
    if (video.currentTime <= newTime + MARGIN) {
        video.play()
        video.addEventListener('progress', p => {
            if (video.currentTime >= newTime) {
                video.currentTime = newTime
                video.pause();
            }
        })
    }
    else {
        var intervalRewind = setInterval(function() {
            if( video.currentTime <= newTime + MARGIN){
               video.currentTime = newTime;
               clearInterval(intervalRewind);
               video.pause();
            }
            else {
                video.currentTime += -(1/fps);
            }
        }, 1000 / fps);
    }
};
