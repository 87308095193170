import { $q, $$, on, append, loadScripts } from "../dom/events.js";
import { Get, SetLS } from "../dom/local-storage.js";
import { GUI } from "../three/js/libs/dat.gui.min.js";
import Stats from "../three/js/libs/stats.min.js";
import ScrollTrigger from "../gsap/ScrollTrigger.js";
import SplitText from "../gsap/SplitText3.js";

let generatedCubeRenderTarget,
    ldrCubeRenderTarget,
    hdrCubeRenderTarget,
    rgbmCubeRenderTarget;
let ldrCubeMap, hdrCubeMap, rgbmCubeMap;

let SCENE,
    CAMERA,
    BUILDING,
    ANIMATIONS = [],
    KEYS = {},
    ANIMATION_UI,
    ACTIONS = [],
    RAYCASTER,
    INTERSECTABLE = [],
    CAR,
    CONTROLS,
    COMPOSER,
    CLOCK,
    MIXER,
    STATS,
    RENDERER,
    THROTTLED = [],
    NOT_PROD = process.env.NODE_ENV !== "production";

// const ASSET_DIR = '/static/3D/spacecity/'
// const CONFIG = { ASSETS: ['spacecity2.gltf'] }

const tick = () => {
    updateCubeMap();
    // updateCar()
    // updateCamera()
    animateScene();
};

// const updateCamera = () => {
//     if (!CAMERA) return;
//     const dir = inOut ? .001 : .001;
//     CAMERA.position.y += dir;
// }

const V1 = 0.002;
const updateCar = () => {
    if (!CAR) return;
    CAR.position.x += V1;
    CAR.position.z += V1;
    CAR.position.y += 0.001;
};

const onRayCast = (event) => {
    if (!CAMERA) return;
    const mouse = {
        x: (event.clientX / RENDERER.domElement.clientWidth) * 2 - 1,
        y: -(event.clientY / RENDERER.domElement.clientHeight) * 2 + 1,
    };
    RAYCASTER.setFromCamera(mouse, CAMERA);
    const intersects = RAYCASTER.intersectObjects(INTERSECTABLE, false);
    if (intersects.length > 0) {
        window.location.href = intersects[0].object.userData.link;
    }
};

const onRayMove = (event) => {
    if (!CAMERA) return;
    const mouse = {
        x: (event.clientX / RENDERER.domElement.clientWidth) * 2 - 1,
        y: -(event.clientY / RENDERER.domElement.clientHeight) * 2 + 1,
    };
    RAYCASTER.setFromCamera(mouse, CAMERA);
    const intersects = RAYCASTER.intersectObjects(INTERSECTABLE, false);
    if (intersects.length > 0) {
        $q("body").style.cursor = "pointer";
        // console.log("MOVE", intersects)
    } else {
        $q("body").style.cursor = "default";
    }
};

const updateCubeMap = () => {
    let renderTarget, cubeMap;
    renderTarget = ldrCubeRenderTarget;
    cubeMap = ldrCubeMap;
    const newEnvMap = renderTarget ? renderTarget.texture : null;
    SCENE.background = cubeMap;
    RENDERER.toneMappingExposure = 1;
};

const videoTexture = (src, cls) => {
    const video = `<video src="${src}" autoplay muted loop playsinline class="hidden ${cls}"></video>`;
    append($q(".home-page"), video);
    const videoT = new THREE.VideoTexture($q(`video.${cls}`));
    video;
    videoT.minFilter = THREE.LinearFilter;
    videoT.magFilter = THREE.LinearFilter;
    return [video, videoT];
};

const PREVIEWS = {
    im: "/client-work/ingram-micro/",
    vt: "/client-work/varsity-tutors/",
    nada: "/client-work/nadamoo/",
    th: "/client-work/trust-her/",
    fads: "/client-work/fred-astaire/",
    csf: "/startups/cinema-set-free/",
    fan: "/startups/fantrotter/",
    spins: "/startups/spins-fm/",
    h2h: "/startups/head2head/",
};
const PREVIEW_KEYS = Object.keys(PREVIEWS);

const addToScene = (gltfs) => {
    gltfs.map((gltf) => {
        ANIMATIONS = ANIMATIONS.concat(gltf.animations);
        gltf.scene.traverse((obj) => {
            if (obj.type === "PerspectiveCamera" && !CAMERA) {
                CAMERA = obj;
            }
            if (obj.name === "Building") {
                obj.material = new THREE.MeshLambertMaterial({
                    color: 0x000000,
                    envMap: ldrCubeMap,
                    combine: THREE.MixOperation,
                    reflectivity: 0.2,
                });
            }
            if (obj.material && obj.material.name === "Screen") {
                INTERSECTABLE.push(obj);
                if (PREVIEW_KEYS.includes(obj.name)) {
                    const key = obj.name; // requires the GLTF names be correct.
                    let [video, map] = videoTexture(
                        `/static/videos/web/${key}-preview.webm`,
                        key
                    );
                    obj.userData.link = PREVIEWS[key];
                    obj.material = new THREE.MeshBasicMaterial({
                        map,
                        color: 0x666666,
                    });
                }
            }
            if (obj.name === "CarCenter") CAR = obj;
            // if (obj.material && obj.material.name === "Frame") {
            //     obj.material = new THREE.MeshLambertMaterial({ color: 0x111111 })
            // }

            // if (obj.material && obj.material.name === "Read Gold") {
            //     obj.material = new THREE.MeshLambertMaterial( { color: 0xE7BB71,
            //             envMap: ldrCubeMap, combine: THREE.MixOperation, reflectivity: 0.2 } );
            // }
            // if (obj.type === "Mesh") {
            //     obj.receiveShadow = true
            //     obj.castShadow = true;
            // }
            obj.frustumCulled = false;
        });
        $$("video").map((v) => v.play());
        SCENE.add(gltf.scene);
        ORIGINAL_POS_Y = CAMERA.position.y;
        MOBILE_Y = CAMERA.position.y + 3.5;
        COMPOSER = new THREE.EffectComposer(RENDERER);
        const renderPass = new THREE.RenderPass(SCENE, CAMERA);
        COMPOSER.addPass(renderPass);
        const bloomPass = new THREE.UnrealBloomPass(
            { x: 2048, y: 2048 },
            1,
            0.0,
            0.75
        );
        COMPOSER.addPass(bloomPass);
        MIXER = createMixer(SCENE);
    });
    setupScroll();
};

let pmremGenerator;
const addCubeMap = () => {
    const ldrUrls = [
        "px.webp",
        "nx.webp",
        "py.webp",
        "ny.webp",
        "pz.webp",
        "nz.webp",
    ];
    ldrCubeMap = new THREE.CubeTextureLoader()
        .setPath("/static/3D/spacecity/cubemap/")
        .load(ldrUrls, function () {
            ldrCubeMap.encoding = THREE.sRGBEncoding;
            ldrCubeRenderTarget = pmremGenerator.fromCubemap(ldrCubeMap);
        });

    pmremGenerator = new THREE.PMREMGenerator(RENDERER);
    pmremGenerator.compileCubemapShader();
    // ldrCubeMap.mapping = THREE.CubeRefractionMapping
};

const addControls = () => {
    if (!CAMERA) {
        setTimeout(() => addControls(), 500);
        return;
    }
    CONTROLS = new THREE.OrbitControls(CAMERA, RENDERER.domElement);
    CONTROLS.minDistance = 5;
    CONTROLS.maxDistance = 300;
};

const houseLights = () => {
    const ambLight = new THREE.AmbientLight(0xffffff, 5);
    const hemi = new THREE.DirectionalLight(0xffffff, 5);
    hemi.position.set(3, 3, 3);
    SCENE.add(ambLight);
    SCENE.add(hemi);
};

function init() {
    console.log("init");
    container = document.querySelector(".canvas-container");
    CLOCK = new THREE.Clock();
    RAYCASTER = new THREE.Raycaster();
    SCENE = new THREE.Scene();
    RENDERER = new THREE.WebGLRenderer({ antialias: true });
    RENDERER.physicallyCorrectLights = true;
    RENDERER.toneMapping = THREE.ACESFilmicToneMapping;
    RENDERER.setPixelRatio(window.devicePixelRatio);
    RENDERER.setSize(window.innerWidth, window.innerHeight);
    // RENDERER.toneMapping = THREE.ReinhardToneMapping;
    RENDERER.outputEncoding = THREE.sRGBEncoding;
    container.appendChild(RENDERER.domElement);
    addCubeMap();
    const assets = container.dataset.src.split(",");
    loadAssets(assets, addToScene);
    // houseLights()
    // addControls()
    // renderVideo()
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
    window.addEventListener("keydown", (e) => keyDownHandler(e));
    window.addEventListener("keypress", (e) => keyDownHandler(e));
    window.addEventListener("keyup", (e) => keyUpHandler(e));
    window.addEventListener("click", onRayCast);
    window.addEventListener("mousemove", onRayMove);
    THREE.DefaultLoadingManager.onLoad = function () {
        pmremGenerator.dispose();
    };
}

const renderVideo = (video) => {
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
        videoImageContext.drawImage(video, 0, 0);
        if (videoTexture) videoTexture.needsUpdate = true;
    }
};

const loadSuccess = (loaded) => {
    // console.log("Loaded: ", loaded)
};

const loadError = (error) => {
    console.error("Error: ", error);
};

const loadProgress = (itemUrl, itemsLoaded, itemsTotal) => {
    // console.log("Progress: ", itemUrl, itemsLoaded, itemsTotal)
};

const loadModel = (url) => {
    return new Promise((resolve) => {
        return new THREE.GLTFLoader().load(
            url,
            resolve,
            loadProgress,
            loadError
        );
    });
};

export const loadAssets = (assets, callback) => {
    const promises = assets.map((a) => loadModel(a));
    Promise.all(promises)
        .then(callback)
        .catch((e) => {
            console.error(e);
        });
};

const onWindowResize = () => {
    if (CAMERA && RENDERER) updateCamera(CAMERA, RENDERER);
};

const FOVMIN = 3;
let MOBILE_Y;
const updateCamera = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    CAMERA.aspect = window.innerWidth / window.innerHeight;
    RENDERER.setSize(width, height);
    // console.log(CAMERA.aspect)
    if (CAMERA.aspect > 1) {
        var tanFOV = Math.tan(((Math.PI / 180) * CAMERA.fov) / 2);
        CAMERA.fov = (360 / Math.PI) * Math.atan(tanFOV);
        CAMERA.position.y = ORIGINAL_POS_Y;
        // if (CAMERA.fov < FOVMIN) CAMERA.fov = FOVMIN;
    } else {
        CAMERA.position.y = MOBILE_Y;
    }
    CAMERA.updateProjectionMatrix();
};

const POST_PROCESS = true;
const loop = (time) => {
    try {
        delta = CLOCK.getDelta();
        if (CONTROLS) CONTROLS.update();
        if (MIXER) MIXER.update(delta);
        if (STATS) STATS.update();
        tick(delta, time);
        if (COMPOSER && POST_PROCESS) COMPOSER.render();
        else {
            if (CAMERA) RENDERER.render(SCENE, CAMERA);
        }
        if (!KEYS.Escape) requestAnimationFrame(loop);
    } catch (e) {
        console.error(e);
    }
};

let TIMEOUT;
const loaded = () => {
    const a =
        typeof THREE !== "undefined" &&
        typeof THREE.GLTFLoader !== "undefined" &&
        typeof THREE.UnrealBloomPass !== "undefined";
    return a;
};

const createMixer = (scene) => {
    const mixer = new THREE.AnimationMixer(scene);
    return mixer;
    // console.log("MIXER", mixer, scene)
    // mixer.addEventListener( 'loop', e => {
    // changeAllCarPaints()
    // });

    mixer.addEventListener("finished", (e) => {
        console.log(
            "FINISHED",
            e.action,
            ACTIONS.map((a) => a.name).join(", ")
        );
        if (ACTIONS.length > 0 && ACTIONS[0].name === e.action._clip.name) {
            ACTIONS.shift();
            console.log("SHIFTED", ACTIONS.map((a) => a.name).join(", "));
            runQue();
        }
        if (e.action.timeScale == -1) {
            e.action.timeScale = 1;
        }
        // e.action.reset()
    });
    return mixer;
};

const makeAction = (name) => {
    const clip = THREE.AnimationClip.findByName(ANIMATIONS, name);
    // console.log("CLIP", clip)
    // console.log("REVERSE", reverse)
    const action = MIXER.existingAction(clip) || MIXER.clipAction(clip);
    return action;
};

const queAction = (name, opts) => {
    ACTIONS.push({ name, opts });
    runQue();
};

const runQue = () => {
    if (ACTIONS.length > 0) {
        const { name, opts } = ACTIONS[0];
        playAction(name, opts);
    }
};

const playAction = (name, opts) => {
    let loop, reverse, paused;
    if (opts) {
        loop = opts.loop;
        reverse = opts.reverse;
        paused = opts.paused;
    }
    const action = makeAction(name);
    // console.log("ACTION", action)
    // const dur = action.getClip().duration;
    if (!loop) action.setLoop(THREE.LoopOnce);
    if (paused) action.paused = true;
    if (reverse) {
        let t = action.time;
        action.reset();
        action.time = t;
        action.timeScale = -1;
    } else {
        action.reset();
        const startTime = action._clip.tracks[0].times[0];
        action.time = startTime;
        action.clampWhenFinished = true;
        action.timeScale = 1;
    }
    console.log("ACTION", action);
    action.play();
    return action;
};

const keyUpHandler = (event) => {
    let key = event.key;
    if (event.keyCode == 32) key = "Space";
    KEYS[key] = false;
};

const THROTTLE_TIME = 1.0;
const keyDownHandler = (event) => {
    let key = event.key;
    if (event.keyCode == 32) key = "Space";
    // Allow for throttling of key downs.
    KEYS[key] = false;
    if (THROTTLED.includes(key)) {
        if (THROTTLE[key] > CLOCK.elapsedTime - THROTTLE_TIME) return;
        THROTTLE[key] = CLOCK.elapsedTime;
    }

    KEYS[key] = true;
    // console.log("KEY", event.key, event.keyCode, key)
    if (KEYS["ArrowUp"]) {
        currentGear++;
        if (currentGear > TOP_GEAR) currentGear = TOP_GEAR;
    } else if (KEYS["ArrowDown"]) {
        currentGear--;
        if (currentGear < 0) currentGear = 0;
    }
    if (KEYS["CapsLock"]) {
        // CAMERA.position.y = (CAMERA.position.y === birdsEyeView) ? originalPosition : birdsEyeView
        // CONTROLS_ACTIVE = !CONTROLS_ACTIVE
        // console.log("CONTROLS", CONTROLS_ACTIVE)
    }
    // console.log("GEAR", currentGear)
};

currentSection = 0;
const scrollT = (el, options) => {
    return {
        scrollTrigger: {
            trigger: el,
            endTrigger: el,
            ...options,
            markers: NOT_PROD,
            immediateRender: false,
            onUpdate: (s) => updateScroll(s),
        },
    };
};

let CURRENT_PROGRESS = 0;
const updateScroll = (s) => {
    CURRENT_PROGRESS = s.progress;
    return s;
};

let SCRUB_NAMES = [],
    SCRUB_ANIMATIONS = [];
SCRUB_NAMES = ["CameraAction", "Rotator1", "LightAction"];
// SCRUB_NAMES.push(['CameraAction1'])
// SCRUB_NAMES.push(['CameraAction2'])
// SCRUB_NAMES.push(['CameraAction3'])

let CAN_SCROLL = true;
const canChange = (e) => {
    CAN_SCROLL = true;
};

const lerp = (v0, v1, t) => {
    return v0 * (1 - t) + v1 * t;
};

const flip = (x) => {
    return 1 - x;
};

const square = (x) => {
    return x * x;
};

const easeOut = (t) => {
    return flip(square(flip(t)));
};

const setupScroll = () => {
    if (NOT_PROD) {
        ANIMATION_UI = new GUI();
        createAnimationUI();
    }
    for (var i = 0; i < SCRUB_NAMES.length; i++) {
        s = SCRUB_NAMES;
        SCRUB_ANIMATIONS = ANIMATIONS.filter((clip) => {
            if (s.includes(clip.name)) return clip;
        });
    }
    TIMELINE = gsap.timeline(
        scrollT(".scroller", {
            pin: ".canvas-container",
            scrub: true,
            start: "top top",
            end: "bottom bottom",
        })
    );
    loop();
};

const createAnimationUI = () => {
    const animationsFolder = ANIMATION_UI.addFolder("Animations");
    const animations = {};
    ANIMATIONS.map((a) => {
        animations[a.name] = () => {
            playAction(a.name);
        };
        animationsFolder.add(animations, a.name);
    });
    animationsFolder.open();
};

const playFrame = (action, clip, newTime) => {
    action.time = newTime;
    action.play();
    action.paused = true;
};

let screen1,
    cursor = 0,
    lt = 0;
const animateScene = () => {
    lt += 0.02;
    cursor = lerp(cursor, CURRENT_PROGRESS, easeOut(lt));
    if (lt > 1) lt = 0;
    if (SCRUB_ANIMATIONS.length === 0) return;
    for (let s in SCRUB_ANIMATIONS) {
        const clip = SCRUB_ANIMATIONS[s];
        const newTime = clip.duration * cursor;
        const action = MIXER.clipAction(clip);
        playFrame(action, clip, newTime);
    }
    if (CAMERA) CAMERA.updateProjectionMatrix();
};

const useGSAP = () => {
    if (typeof SplitText === "undefined" || typeof gsap === "undefined") {
        setTimeout(useGSAP, 100);
        return;
    }
    const s1 = new SplitText("#opening .heading", { type: "lines" });
    const TIMELINE1 = gsap.timeline();
    TIMELINE1.from(
        "#opening .heading",
        { duration: 0.7, opacity: 0, x: 200, ease: "out", stagger: 0.2 },
        "+=0"
    ).from(
        s1.lines,
        {
            duration: 0.7,
            opacity: 0,
            scale: 0.3,
            y: 10,
            rotationZ: 0,
            ease: "out",
            stagger: 0.2,
        },
        "-=.7"
    );
};

let trying = false;
const tryToLoad = () => {
    if (trying) return;
    trying = true;
    if (loaded()) {
        window.removeEventListener("scroll", tryToLoad);
        clearTimeout(TIMEOUT);
        init();
        return;
    }
    if (typeof THREE === "undefined") {
        loadScripts(["/js/lib/three/three.js"]);
        clearTimeout(TIMEOUT);
    }
    if (
        typeof THREE !== "undefined" &&
        typeof THREE.GLTFLoader === "undefined"
    ) {
        loadScripts([
            "/js/lib/three/js/loaders/GLTFLoader.js",
            "/js/components/bloom.js",
        ]);
    }
    TIMEOUT = setTimeout(() => {
        tryToLoad();
    }, 1000);
    trying = false;
};
if (window.scrollY > 200) tryToLoad();
window.addEventListener("scroll", tryToLoad);
useGSAP();
